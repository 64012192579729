import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Popper, Switcher } from '@hse-design/react';
import { useState, useRef, useEffect } from 'react';
import { Playground, Props } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "popper"
    }}>{`Popper`}</h1>
    <pre><code parentName="pre" {...{}}>{`import { Popper } from '@hse-design/react';
`}</code></pre>
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Popper - это абстрактный компонент, оборачивающий логику позиционирования плавающего элемента из библиотеки `}<a parentName="p" {...{
        "href": "https://popper.js.org/"
      }}>{`Popper.js`}</a>{`.`}</p>
    <p>{`Внутри ДС Popper используется для реализации компонентов `}<a parentName="p" {...{
        "href": "/src-components-tooltip"
      }}>{`Tooltip`}</a>{` и `}<a parentName="p" {...{
        "href": "/src-components-popover"
      }}>{`Popover`}</a>{`, и скорее всего вам нужны именно они.`}</p>
    <p>{`С помощью Popper вы можете реализовать специализированные под ваш продукт компоненты.`}</p>
    <Playground __position={0} __code={'() => {\n  const [anchor, setAnchor] = useState()\n  return (\n    <>\n      <div ref={setAnchor} style={{ width: \'150px\', background: \'#C0C6D1\' }}>\n        Anchor\n      </div>\n      <Popper appendTo={anchor}>\n        <div\n          style={{ width: \'50px\', height: \'50px\', background: \'#7C89A3\' }}\n        />\n      </Popper>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Popper,
      Switcher,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [anchor, setAnchor] = useState();
        return <>
      <div ref={setAnchor} style={{
            width: '150px',
            background: '#C0C6D1'
          }}>Anchor</div>
      <Popper appendTo={anchor} mdxType="Popper">
        <div style={{
              width: '50px',
              height: '50px',
              background: '#7C89A3'
            }} />
      </Popper>
    </>;
      }}
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "привязка-к-произвольным-координатам"
    }}>{`Привязка к произвольным координатам`}</h3>
    <p>{`Popper можно привязать не только к какому-то элементу страницы, но и к произвольным координатам на экране.
Для этого используйте VirtualElement в соответствии с документацией `}<a parentName="p" {...{
        "href": "https://popper.js.org/docs/v2/virtual-elements/"
      }}>{`Popper.js`}</a>{`.`}</p>
    <Playground __position={1} __code={'() => {\n  const [active, setActive] = useState(false)\n  const [anchor, setAnchor] = useState(\n    () =>\n      new (class {\n        constructor() {\n          this.x = 0\n          this.y = 0\n        }\n        getBoundingClientRect() {\n          return {\n            width: 0,\n            height: 0,\n            top: this.y,\n            right: this.x,\n            bottom: this.y,\n            left: this.x,\n          }\n        }\n      })(),\n  )\n  const popperRef = useRef()\n  useEffect(() => {\n    const handleMouseMove = e => {\n      const { clientX: x, clientY: y } = e\n      anchor.x = x\n      anchor.y = y\n      popperRef.current && popperRef.current.update()\n    }\n    window.addEventListener(\'mousemove\', handleMouseMove)\n    return () => window.removeEventListener(\'mousemove\', handleMouseMove)\n  }, [anchor])\n  return (\n    <>\n      <Switcher checked={active} onChange={setActive} label=\"Enabled\" />\n      {active && (\n        <Popper\n          ref={popperRef}\n          appendTo={anchor}\n          style={{ pointerEvents: \'none\' }}\n          placement=\"bottom-start\"\n        >\n          <div\n            style={{ width: \'50px\', height: \'50px\', background: \'#7C89A3\' }}\n          />\n        </Popper>\n      )}\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Popper,
      Switcher,
      useState,
      useRef,
      useEffect,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [active, setActive] = useState(false);
        const [anchor, setAnchor] = useState(() => new class {
          constructor() {
            this.x = 0;
            this.y = 0;
          }

          getBoundingClientRect() {
            return {
              width: 0,
              height: 0,
              top: this.y,
              right: this.x,
              bottom: this.y,
              left: this.x
            };
          }

        }());
        const popperRef = useRef();
        useEffect(() => {
          const handleMouseMove = e => {
            const {
              clientX: x,
              clientY: y
            } = e;
            anchor.x = x;
            anchor.y = y;
            popperRef.current && popperRef.current.update();
          };

          window.addEventListener('mousemove', handleMouseMove);
          return () => window.removeEventListener('mousemove', handleMouseMove);
        }, [anchor]);
        return <>
      <Switcher checked={active} onChange={setActive} label="Enabled" mdxType="Switcher" />
      {active && <Popper ref={popperRef} appendTo={anchor} style={{
            pointerEvents: 'none'
          }} placement="bottom-start" mdxType="Popper">
        <div style={{
              width: '50px',
              height: '50px',
              background: '#7C89A3'
            }} />
      </Popper>}
    </>;
      }}
    </Playground>
    <h2 {...{
      "id": "параметры"
    }}>{`Параметры`}</h2>
    <Props of={Popper} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      